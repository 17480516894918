export const SituacaoParcela = {
  0: "Em aberto",
  1: "Paga",
  2: "Negociada",
};

export default class Parcela {
  constructor(parcela) {
    Object.assign(this, parcela);
    this._verificando = false;
  }

  get verificando() {
    return this._verificando;
  }

  set verificando(value) {
    this._verificando = value;
  }

  get valorPago() {
    return this.valorpago;
  }

  get valorEmAberto() {
    return (this.valor + this.multa + this.juros - this.valorPago - this.desconto).toFixed(2);
  }

  get estaEmAberto() {
    return this.situacao === 0;
  }

  get estaPaga() {
    return this.situacao === 1;
  }

  get estaNegociada() {
    return this.situacao === 2;
  }

  get situacaoEmExtenso() {
    try {
      const situacaoEmExtenso = SituacaoParcela[this.situacao];
      return situacaoEmExtenso;
    } catch (error) {
      return "Situação desconhecida";
    }
  }

  get verificando() {
    return false;
  }
}
