var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"value":_vm.menuOpen,"color":"grey lighten-5","app":""},on:{"input":(newValue) => _vm.$store.commit('MainTemplate/setMenuOpen', newValue)},scopedSlots:_vm._u([{key:"append",fn:function(){return [(
        _vm.user.id !== 298 &&
        _vm.user.roleName !== 'coordenador' &&
        _vm.user.roleName !== 'professor' &&
        _vm.user.roleName !== 'aluno'
      )?_c('e-situacao-caixa'):_vm._e()]},proxy:true}])},[_c('v-list-item',{staticClass:"px-2 py-5"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex justify-center"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('img',{staticClass:"mb-5",attrs:{"src":"/img/logo.png","width":"100"}})])],1),_c('v-list-item-subtitle',{staticClass:"d-flex justify-center"},[_c('e-ano-selector',{})],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menu.items.filter(
        (item) =>
          item.exibir && item.children.filter((child) => _vm.$can(child.permissions)).length > 0
      )),function(item){return _c('v-list-group',{key:item.title,attrs:{"no-action":"","append-icon":item.children && item.children.length > 0 ? undefined : '',"prepend-icon":item.icon},on:{"click":() => (item.to ? _vm.$router.push(item.to) : undefined)},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}})],1)]},proxy:true}],null,true),model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}},_vm._l((item.children),function(child){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.$can(child.permissions)),expression:"$can(child.permissions)"}],key:child.title,attrs:{"to":child.to,"href":child.href,"target":child.target}},[(child.type === 'spacer')?_c('v-divider'):_vm._e(),(child.type !== 'spacer')?_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(child.title)}})],1):_vm._e()],1)}),1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }