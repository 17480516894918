<template>
  <main-template>
    <v-container>
      <template v-if="!parametroConsulta">
        <v-row>
          <v-col cols="12">
            <h1>Gestão de Mensalidades</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-if="matriculas"
              :items="matriculas"
              :loading="loadingMatriculas"
              :item-text="
                (matricula) =>
                  `${matricula.aluno.codigo} - ${matricula.aluno.nomecompleto} - ${matricula.turma.descricao}`.toUpperCase()
              "
              :filter="searchQuery"
              label="Qual matrícula você quer selecionar?"
              return-object
              dense
              cache-items
              hide-no-data
              solo
              @change="selecionarMatricula"
            />
          </v-col>
        </v-row>
      </template>

      <v-row v-if="loadingParcelas">
        <v-col cols="12">
          <v-skeleton-loader loading type="table" />
        </v-col>
      </v-row>

      <v-row v-if="!loadingParcelas">
        <v-col cols="12">
          <v-col class="d-flex justify-end">
            <div class="mb-2" v-if="parcelasCriadas === true || !parametroConsulta">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!matriculaSelecionada.id || !user.caixa"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => getCarneDePagamento(matriculaSelecionada.id, 'boletos')"
                    class="mr-3"
                  >
                    <v-icon small left> fa fa-money </v-icon>
                    Boletos
                  </v-btn>
                </template>
                <span>Gerar Boletos</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!matriculaSelecionada.id || !user.caixa"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-3"
                    @click="() => getCarneDePagamento(matriculaSelecionada.id, 'carne')"
                  >
                    <v-icon small left> fa fa-money </v-icon>
                    Carnê
                  </v-btn>
                </template>
                <span>Gerar Carnê</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="!matriculaSelecionada.id || !user.meu_caixa"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      () => [openModalNovaParcela(matriculaSelecionada), (showNovaParcela = true)]
                    "
                  >
                    <v-icon small left> fa fa-money </v-icon>
                    Nova Parcela
                  </v-btn>
                </template>
                <span>Nova Parcela</span>
              </v-tooltip>
            </div>
          </v-col>

          <e-snippet-matricula :matricula="matriculaSelecionada" />
          <v-row v-if="parametroConsulta && parcelasCriadas === false">
            <v-col class="pt-0 pb-0 mt-4" cols="6">
              <e-label>Valor da Matrícula</e-label>
              <v-currency-field
                @input="changeParcelas"
                v-model="valor_matricula"
                locale="pt-BR"
                hide-details
                ref="input"
                prefix="R$"
                solo
              />
            </v-col>
            <v-col class="pt-0 pb-0 mt-4" cols="6">
              <e-label>Valor da Mensalidade</e-label>
              <v-currency-field
                @input="changeParcelas"
                v-model="valor_mensalidade"
                locale="pt-BR"
                hide-details
                ref="input"
                prefix="R$"
                solo
              />
            </v-col>
            <v-col class="pt-0 pb-0 mt-4" cols="6">
              <e-label>Mês de Início</e-label>
              <v-select
                @change="changeParcelas"
                :items="$constants.meses"
                item-text="descricao"
                item-value="id"
                v-model="mesInicio"
                return-object
                label="Selecione um mês"
                solo
              ></v-select>
            </v-col>
            <v-col class="pt-0 pb-0 mt-4" cols="6">
              <e-label>Mês Final</e-label>
              <v-select
                @change="changeParcelas"
                :items="$constants.meses"
                item-text="descricao"
                item-value="id"
                v-model="mesFinal"
                return-object
                label="Selecione um mês"
                solo
              ></v-select>
            </v-col>
            <v-col class="pt-0 pb-0 mt-0" cols="4">
              <e-label>Dia de Vencimento</e-label>
              <v-text-field
                @input="changeParcelas"
                v-model="vencimento"
                type="number"
                :max="31"
                solo
              />
            </v-col>
            <v-col class="pt-0 pb-0 mt-0" cols="4">
              <e-label>Desconto</e-label>
              <v-currency-field
                @input="changeParcelas"
                v-model="desconto"
                locale="pt-BR"
                hide-details
                ref="input"
                prefix="R$"
                solo
              />
            </v-col>
            <v-col class="pt-0 pb-0 mt-0" cols="4">
              <e-label>Dia/Desconto</e-label>
              <v-text-field v-model="descontoDia" type="number" :max="31" solo />
            </v-col>
          </v-row>
          <v-data-table
            v-if="parametroConsulta && parcelasCriadas === false"
            :headers="table.headersComParam"
            :items="parcelas ? parcelas : []"
            :items-per-page="26"
          >
            <template v-slot:item.checkbox="{ item: parcela }">
              <v-checkbox
                v-model="boletosSelecionados"
                v-if="!parcela.boleto && parcela.situacao < 1"
                :value="parcela"
                class="ms-3 mt-0"
                hide-details
                @click="showHistorico = false"
              ></v-checkbox>
              <v-chip
                v-if="parcela.linhaDigitavel"
                small
                :color="'green'"
                class="white--text ms-2 mt-0"
              >
                <span @click="consultarBoleto(parcela)" class="row-pointer">
                  <v-icon small>fa-building</v-icon>
                </span>
              </v-chip>
            </template>

            <template v-slot:item.referencia="{ item: parcela }">
              {{ parcela.referencia | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
            </template>

            <template v-slot:item.valor="{ item: parcela }">
              {{ parcela.valor | currency }}
            </template>

            <template v-slot:item.valorpago="{ item: parcela }">
              <v-chip small :color="parcela.valorPago > 0 ? 'green' : 'red'" class="white--text">
                {{ parcela.valorpago | currency }}
              </v-chip>
            </template>

            <template v-slot:item.valordesconto="{ item: parcela }">
              {{ parcela.desconto | currency }}
            </template>

            <template v-slot:item.valoraberto="{ item: parcela }">
              <v-chip color="green" class="white--text">
                {{ (parcela.valor - parcela.valorpago - parcela.desconto) | currency }}
              </v-chip>
            </template>
          </v-data-table>

          <v-data-table
            v-else
            :headers="table.headers"
            :items="parcelas ? parcelas : []"
            :items-per-page="15"
            :header-props="{ sortIcon: null }"
          >
            <template v-slot:header.checkbox v-if="parcelas.length > 0">
              <div class="row align-center mt-0 mb-0">
                <v-checkbox
                  v-model="selectAll"
                  @click="toggleSelectAll"
                  class="ms-6"
                  hide-details
                ></v-checkbox>
                <span class="mt-5 font-weight-medium">Marcar todos</span>
              </div>
            </template>
            <template v-slot:item.checkbox="{ item: parcela }">
              <v-checkbox
                v-model="boletosSelecionados"
                v-if="!parcela.boleto && parcela.situacao < 1"
                :value="parcela"
                class="ms-3 mt-0"
                hide-details
                @click="showHistorico = false"
              ></v-checkbox>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="parcela.linhaDigitavel"
                    x-small
                    color="green"
                    class="white--text"
                    style="border-radius: 100px; padding-left: 21px; width: 10%"
                    v-bind="attrs"
                    v-on="on"
                    @click="consultarBoleto(parcela)"
                  >
                    <v-icon small left>fa-building</v-icon>
                  </v-btn>
                </template>
                <span>Imprimir Boleto</span>
              </v-tooltip>
            </template>

            <template v-slot:item.referencia="{ item: parcela }">
              {{ parcela.referencia | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
            </template>

            <template v-slot:item.valor="{ item: parcela }">
              {{ parcela.valor | currency }}
            </template>

            <template v-slot:item.valorpago="{ item: parcela }">
              <v-chip small :color="parcela.valorPago > 0 ? 'green' : 'red'" class="white--text">
                {{ parcela.valorpago | currency }}
              </v-chip>
            </template>

            <template v-slot:item.valordesconto="{ item: parcela }">
              {{ parcela.desconto | currency }}
            </template>

            <template v-slot:item.valoraberto="{ item: parcela }">
              <v-chip color="green" class="white--text">
                {{ calcularValorAberto(parcela) | currency }}
              </v-chip>
            </template>

            <template v-slot:item.situacao="{ item: parcela }">
              <e-situacao-parcela small :parcela="parcela" />
            </template>

            <template v-slot:item.actions="{ item: parcela }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    color="green darken-3 mr-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    :loading="parcela._verificando"
                    @click.stop="verificarSituacao(parcela)"
                  >
                    <v-icon small> mdi-refresh </v-icon>
                  </v-btn>
                </template>
                <span>Verificar Situação</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="parcela.estaPaga || parcela.estaNegociada || !user.caixa"
                    x-small
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="() => openModalBaixarParcela(parcela, matriculaSelecionada)"
                  >
                    <v-icon small> fa fa-download </v-icon>
                  </v-btn>
                </template>
                <span>Baixar parcela</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    color="primary ml-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="
                      () => [historico(parcela.id), (showHistorico = true), scrollToTop()]
                    "
                  >
                    <v-icon small> mdi-list-status </v-icon>
                  </v-btn>
                </template>
                <span>Histórico</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="parcela.estaPaga"
                    x-small
                    color="red ml-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="() => openModalDeletar(parcela)"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>Deletar </span>
              </v-tooltip>
            </template>
          </v-data-table>

          <v-sheet v-if="parcelasCriadas === false && parametroConsulta">
            <v-btn
              color="primary"
              :disabled="parcelas.length < 1"
              class="ms-3 mb-3"
              @click="vincularParcelas()"
            >
              Gerar Parcelas
            </v-btn>
          </v-sheet>
          <v-sheet v-else>
            <v-btn
              color="primary"
              :disabled="boletosSelecionados.length < 1"
              class="ms-3 mb-3"
              @click="registrarBoletosSelecionados()"
            >
              Registrar boletos selecionados
            </v-btn>
          </v-sheet>
        </v-col>

        <v-dialog v-model="showDialog" persistent max-width="400">
          <v-card>
            <v-card-title class="text-h5"> Caixa fechado </v-card-title>
            <v-card-text>Por favor abra o caixa antes de continuar.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="[(showDialog = false), abrirCaixa()]">
                Abrir Caixa
              </v-btn>
              <v-btn color="green darken-1" text @click="[(showDialog = false)]"> Continuar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="modalDeletarMensalidade" max-width="450">
          <v-card>
            <v-card-title class="text-h5"> Deseja excluir essa mensalidade? </v-card-title>
            <v-card-title class="text-h5"> </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="modalDeletarMensalidade = false">
                Continuar
              </v-btn>
              <v-btn color="green darken-1" text @click="modalDeletarMensalidade = false">
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <NovaParcela
          @matriculasVinculadas="refresh()"
          @status="handleStatus"
          :showNovaParcela="showNovaParcela"
          :matricula="matriculaSelecionada.id"
        />
      </v-row>
    </v-container>

    <Drawer
      :show="showHistorico"
      :parcela="parcela"
      :listaDeParcelas="listaDeParcelas"
      @valorEstornado="loadAgain()"
    />
  </main-template>
</template>
<script>
import { mapGetters } from "vuex";
import searchQuery from "@/plugins/searchQuery";
import Drawer from "@/components/Mensalidades/Drawer.vue";
import NovaParcela from "@/components/Mensalidades/NovaParcela.vue";

export default {
  name: "MensalidadesPage",
  components: {
    Drawer,
    NovaParcela,
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      valor_mensalidade: 0,
      valor_matricula: 0,
      parcelasCriadas: false,
      desconto: 0,
      selectAll: false,
      descontoDia: null,
      mesInicio: { id: 1 },
      mesFinal: { id: 12 },
      vencimento: 15,
      modalDeletarMensalidade: false,
      estornoMessage: false,
      showDialog: false,
      showNovaParcela: false,
      listaDeParcelas: [],
      parcela: {},
      showHistorico: false,
      matriculaSelecionada: {},
      matriculas: [],
      parcelas: [],
      parcelaSelecionada: {},
      boletosSelecionados: [],
      searchQuery,
      loadingMatriculas: true,
      loadingParcelas: false,
      modalBaixarParcela: { open: false, parcela: null },
      table: {
        headers: [
          { text: "Boleto", value: "checkbox" },
          { text: "Nº", value: "numero" },
          { text: "Referência", value: "referencia" },
          { text: "Valor", value: "valor" },
          { text: "Valor Pago", value: "valorpago" },
          { text: "Valor Desconto", value: "valordesconto" },
          { text: "Valor Aberto", value: "valoraberto" },
          { text: "Situação", value: "situacao", align: "center" },
          { text: "Ações", value: "actions", sortable: false },
        ],
        headersComParam: [
          { text: "Nº", value: "numero" },
          { text: "Referência", value: "referencia" },
          { text: "Valor", value: "valor" },
        ],
      },
      parametroConsulta: null,
    };
  },

  mounted() {
    this.parametroConsulta = this.$route.query.matricula;

    this.loadMatriculas();
    if (!this.user.caixa) {
      this.showDialog = true;
      if (this.parametroConsulta) {
        this.showDialog = false;
      }
    }
  },

  methods: {
    calcularValorAberto(parcela) {
      const diff = parcela.valor - parcela.valorpago - parcela.desconto;
      return Math.abs(diff) < 0.000001 ? 0 : diff;
    },

    createParcelas() {},
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    changeParcelas() {
      this.parcelas = this.criarParcelas(
        [this.mesInicio, this.mesFinal],
        this.vencimento,
        this.desconto
      );
    },

    toggleSelectAll() {
      if (this.selectAll) {
        this.boletosSelecionados = this.parcelas.filter(
          (parcela) => !parcela.boleto && parcela.situacao < 1
        );
      } else {
        this.boletosSelecionados = [];
      }
    },

    criarParcelas(intervalo, diaVencimento, desconto) {
      const hoje = new Date();
      const anoAtual = hoje.getFullYear();

      const parcelas = [];
      const parcelasEMatricula = [];

      // Ajuste para garantir que a primeira parcela seja gerada no mês de janeiro do ano atual
      const dataPrimeiraParcela = new Date(anoAtual, this.mesInicio.id - 1, diaVencimento);

      for (let i = intervalo[0].id - 1; i <= intervalo[1].id - 1; i++) {
        const dataParcela = new Date(anoAtual, i, diaVencimento);

        const parcela = {
          situacao: 0,
          numero: i + 1,
          referencia: dataParcela.toISOString().split("T")[0],
          valor: this.valor_mensalidade - desconto,

          matricula: this.matriculaSelecionada.id,
          valorMensal: this.valor_mensalidade,
          data: dataParcela.toISOString().split("T")[0],
          desconto: this.desconto,
          vencimento: dataParcela.toISOString().split("T")[0],
        };

        parcelas.push(parcela);
      }

      const matric = {
        situacao: 0,
        numero: 1,
        referencia: dataPrimeiraParcela.toISOString().split("T")[0],
        valor: this.valor_matricula,

        matricula: this.matriculaSelecionada.id,
        valorMensal: this.valor_matricula,
        data: dataPrimeiraParcela.toISOString().split("T")[0],
        vencimento: dataPrimeiraParcela.toISOString().split("T")[0],
      };

      parcelasEMatricula.push(matric);

      parcelas.shift(); // Remove o primeiro objeto

      parcelas.forEach((element) => {
        parcelasEMatricula.push(element);
      });

      return parcelasEMatricula;
    },

    async vincularParcelas() {
      this.$loaderService.open("Vinculando matrículas");
      try {
        const formulario = [...this.parcelas];
        const parcelasData = await this.$services.parcelasService.novaParcela({
          formulario,
        });
        this.$loaderService.close();
        this.parcelasCriadas = true;
        this.loadParcelas(this.matriculaSelecionada);
      } catch (error) {
        this.$handleError(error);
        this.$loaderService.close();
      }
      this.$loaderService.close();
    },

    openModalDeletar(parcela) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja excluir esta parcela?",
        confirmationCode: parcela.id,
        confirmationMessage: `Por favor, digite <strong>${parcela.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a Parcela");
              try {
                await this.$services.parcelasService.deletarParcela(parcela.id);
                this.$toast.success("Parcela excluida com sucesso");
                this.$forceUpdate();
                this.refresh();
              } catch (error) {
                this.$handleError(error);
              } finally {
                this.$loaderService.close();
              }
            },
            props: {
              color: "error",
              small: true,
            },
          },
        ],
      });
    },

    consultarBoleto(parcela) {
      this.$loaderService.open("Consultanto Boleto");
      // Implementar a pesquisa ao boleto
      this.$services.parcelasService
        .consultarBoleto(parcela)
        .then((response) => {
          // window.open(response.data)
        })
        .catch((err) => {
          this.$handleError(err.error);
        })
        .finally(() => {
          this.$loaderService.close();
        });
    },

    async registrarBoletosSelecionados() {
      let erroOcorreu = false;
      if (this.boletosSelecionados.length > 0) {
        this.$loaderService.open("Aguarde o sistema está registrando o(s) Boleto(s)");
      }

      for (let index = 0; index < this.boletosSelecionados.length; index++) {
        /* eslint-disable no-await-in-loop */
        const boleto = this.boletosSelecionados[index];
        this.$loaderService.open(
          `Registrando o Boleto ${index + 1} de ${this.boletosSelecionados.length}`
        );
        try {
          const res = await this.$services.parcelasService.incluirBoleto(boleto); // igore this line
          if (res.data.erros && res.data.erros.length) {
            this.$handleError({ message: res.data.erros[0].mensagem });
            // console.log(res.data);
            erroOcorreu = true;
            break;
          } else {
            this.$toast.success("Boleto registrado com sucesso!");
          }
        } catch (err) {
          this.$handleError(err);
          // console.log(err);
          erroOcorreu = true;
          break;
        } finally {
          this.$loaderService.close();
        }
        /* eslint-enable no-await-in-loop */
      }
      if (!erroOcorreu) {
        this.refresh();
        this.boletosSelecionados = [];
      }
    },

    handleStatus(event) {
      this.showNovaParcela = event;
    },
    selecionarMatricula(matricula) {
      this.showNovaParcela = false;
      this.showHistorico = false;

      this.matriculaSelecionada = { ...matricula };
      if (matricula !== this.matriculaSelecionada) {
        this.loadParcelas(matricula);
      }
    },

    async getCarneDePagamento(matricula_id, type) {
      this.showHistorico = false;
      this.loadingParcelas = true;
      try {
        const response = await this.$services.matriculasService.getMatricula(matricula_id, type);
      } catch (exception) {
        this.$handleError(exception);
      } finally {
        this.loadingParcelas = false;
      }
    },

    async loadParcelas(matricula) {
      this.boletosSelecionados = [];
      this.loadingParcelas = true;
      this.matriculaSelecionada = {};
      try {
        const matriculaDetalhada = await this.$services.matriculasService.getDetails(matricula.id);
        this.parcelas = await this.$services.matriculasService.parcelas(
          matriculaDetalhada.parcelas
        );
        this.matriculaSelecionada = { ...matriculaDetalhada };
      } catch (error) {
        this.$handleError(error);
      }
      this.loadingParcelas = false;
    },

    async verifyParcelas(matricula) {
      const matriculaDetalhada = await this.$services.matriculasService.getDetails(matricula.id);
      const parcelas = await this.$services.matriculasService.parcelas(matriculaDetalhada.parcelas);
      if (parcelas.length > 0) {
        this.parcelasCriadas = true;
        this.loadParcelas(this.matriculaSelecionada);
      } else {
        this.parcelas = this.criarParcelas(
          [this.mesInicio, this.mesFinal],
          this.vencimento,
          this.desconto
        );
      }
    },

    async loadMatriculas() {
      this.loadingMatriculas = true;
      try {
        const data = await this.$services.matriculasService.all();
        this.matriculas = data;

        if (this.parametroConsulta) {
          this.showDialog = false;
          const filtro = this.matriculas.filter((mat) => {
            if (mat.id == this.parametroConsulta) {
              return mat;
            }
          });

          this.matriculaSelecionada = filtro[0];

          this.valor_matricula = this.matriculaSelecionada.turma?.valor_matricula ?? 0;
          this.valor_mensalidade = this.matriculaSelecionada.turma?.valor_parcela ?? 0;

          this.verifyParcelas(this.matriculaSelecionada);
        }
      } catch (error) {
        this.$handleError(error.message || error.response.data.error.message);
      }
      this.loadingMatriculas = false;
    },

    async historico(parcela) {
      this.showNovaParcela = false;
      const response = await this.$services.parcelasService.getHistorico(parcela);
      this.parcela = { ...response.data };
      this.listaDeParcelas = [...response.data.mensalidades];
    },

    openModalBaixarParcela(parcela, matriculaSelecionada) {
      this.parcela = { ...parcela };
      this.$router.push({
        name: "mensalidades.baixar",
        params: { prestacao_id: this.parcela.id, matricula_id: matriculaSelecionada.id },
      });
    },

    openModalNovaParcela() {
      this.showHistorico = false;
      this.showNovaParcela = !this.showNovaParcela;
    },

    refresh() {
      this.estornoMessage = true;
      this.showNovaParcela = false;
      this.showHistorico = false;
      this.loadParcelas(this.matriculaSelecionada);
      this.boletosSelecionados = [];
    },

    loadAgain() {
      this.estornoMessage = true;
      this.showNovaParcela = false;
      this.showHistorico = false;
      this.loadParcelas(this.matriculaSelecionada);
      this.$toast.success("Baixa feita com sucesso!");
    },

    async verificarCaixaAberto() {
      if (!this.user.caixa) {
        this.showDialog = true;
      }
    },

    abrirCaixa() {
      this.$router.push({ name: "caixas" });
    },

    async verificarSituacao(parcela) {
      parcela._verificando = false;
      try {
        parcela._verificando = true;
        const response = await this.$services.parcelasService.verificarSituacaoParcela(parcela);
        this.$toast.success(response.data.message || "Situação verificada com sucesso!");
        this.loadParcelas(this.matriculaSelecionada);
      } catch (error) {
        this.$toast.error("Erro ao verificar situação da parcela.");
      } finally {
        parcela._verificando = false;
      }
    },
  },
};
</script>
<style>
.row-pointer:hover {
  cursor: pointer;
}
</style>
